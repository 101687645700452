<template>
  <div class="background">
    <img
      src="@/assets/img/backgroundMap.png"
      style="position: absolute; right: 0"
      width="630px"
      alt="map"
      loading="lazy"
    />
    <Header
      @showNavigationDrawer="$emit('showNavigationDrawer')"
      @showProfileNavigationDrawer="$emit('showProfileNavigationDrawer')"
    />
    <v-main>
      <v-container>
        <h1 class="siteTitle">
          {{ "site_title" | localize
          }}&nbsp;<br v-if="$vuetify.breakpoint.smAndDown" />
          <span class="siteName">P-TRANS</span>
        </h1>
        <p class="siteSubtitle">
          {{ "site_subtitle" | localize }}
        </p>
        <v-row no-gutters justify="center">
          <div class="searchFieldBackground">
            <search-ticket-form />
            <p class="popularRoutesText">
              {{ "popular_destination_label" | localize }}
            </p>
            <v-row no-gutters align="center">
              <div
                class="popularRoutesBox"
                v-for="route in popularSymlinksList"
                :key="route.id"
                @click="
                  $router.push(
                    `/search/${route.departure.id}/${
                      route.destination.id
                    }?departureDate=${new Date()
                      .toISOString()
                      .substring(0, 10)}&qty=1`
                  )
                "
              >
                {{
                  route.departure.translations?.find(
                    (translate) =>
                      translate.lang.toLowerCase() == locale.toLowerCase()
                  )?.name
                }}
                -
                {{
                  route.destination.translations?.find(
                    (translate) =>
                      translate.lang.toLowerCase() == locale.toLowerCase()
                  )?.name
                }}
              </div>
              <div class="moreBox" @click="$router.push('/routes')">
                <p style="margin: 0px">{{ "more_label" | localize }}</p>
                <v-icon size="20px">mdi-chevron-right</v-icon>
              </div>
            </v-row>
          </div>
        </v-row>

        <RoutesComponent
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-top: 80px'
              : 'margin-top: 460px'
          "
        />
      </v-container>
    </v-main>
    <MainSwiper
      :style="
        $vuetify.breakpoint.smAndDown ? 'margin-top: 80px' : 'margin-top: 140px'
      "
    />
    <PopularRouteList
      v-if="popularRouteList.length > 0"
      :routeList="popularRouteList"
    />
    <ChooseUs />
    <div loading="lazy">
      <iframe
        :height="$vuetify.breakpoint.smAndDown ? '375px' : '700px'"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '100%'"
        :src="link"
        frameborder="0"
        allowfullscreen
        loading="lazy"
        title="Youtube video"
      ></iframe>
    </div>
    <AboutUsComponent />
    <OurFleetComponent />
    <ReviewComponent />
    <FAQComponent />
    <BlogComponent />
    <Footer />
    <footer-for-all-pages />
  </div>
</template>

<script>
import Header from "../../Header.vue";
import SearchTicketForm from "../../UI/Forms/searchTicketForm.vue";
// import PopularRouteList from "../../UI/Lists/popularRoutes/popularRouteList.vue";
// import RoutesComponent from "./Routes/mainRoutesComponent.vue";
// import AboutUsComponent from "./aboutUs/aboutUsComponent.vue";
// import ChooseUs from "./chooseUs/chooseUsComponent.vue";
// import FAQComponent from "./FAQ/FAQComponent.vue";
// import MainSwiper from "./mainSwiper.vue";
// import OurFleetComponent from "./ourFleet/ourFleetComponent.vue";
// import ReviewComponent from "./Reviews/reviewComponent.vue";
// import Footer from "@/components/Footer";
// import FooterForAllPages from "../../FooterForAllPages.vue";
// import BlogComponent from "./Blog/blogComponent.vue";
import settingsService from "../../../requests/Admin/settingsService";
import routesService from "../../../requests/Admin/routesService";
import { mapGetters } from "vuex";
export default {
  components: {
    Header,
    SearchTicketForm,
    RoutesComponent: () => import("./Routes/mainRoutesComponent.vue"),
    MainSwiper: () => import("./mainSwiper.vue"),
    PopularRouteList: () =>
      import("../../UI/Lists/popularRoutes/popularRouteList.vue"),
    ChooseUs: () => import("./chooseUs/chooseUsComponent.vue"),
    AboutUsComponent: () => import("./aboutUs/aboutUsComponent.vue"),
    OurFleetComponent: () => import("./ourFleet/ourFleetComponent.vue"),
    ReviewComponent: () => import("./Reviews/reviewComponent.vue"),
    FAQComponent: () => import("./FAQ/FAQComponent.vue"),
    BlogComponent: () => import("./Blog/blogComponent.vue"),
    Footer: () => import("@/components/Footer.vue"),
    FooterForAllPages: () => import("../../FooterForAllPages.vue"),
  },
  data: () => ({
    videoId: "",
    link: "",
    popularRouteList: [],
    popularSymlinksList: [],
  }),
  mounted() {
    this.getSetting();
    this.getPopularRoutes();
  },
  methods: {
    async getSetting() {
      await settingsService.getSettingList().then((res) => {
        if (res.status == "Success") {
          this.link = this.convertToEmbedUrl(
            (this.youtube_link = res.data.filter(
              (item) => item.key == "youtube_link"
            )?.[0]?.value)
          );
        }
      });
    },
    async getPopularRoutes() {
      await routesService.getPopularRoutes().then((res) => {
        if (res.status == "Success") {
          this.popularRouteList = res.data.routes;
          this.popularSymlinksList = res.data.symlink;
        }
      });
    },
    convertToEmbedUrl(youtubeUrl) {
      const url = new URL(youtubeUrl);
      const videoId = url.searchParams.get("v");
      return `https://www.youtube.com/embed/${videoId}`;
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
};
</script>

<style lang="scss">
@media only screen and (min-width: 1024px) {
  .background {
    background-color: #172b69;
    height: 420px;
    margin: 0;
  }
  .siteTitle {
    color: #fafafa;
    font-family: "MacPaw Fixel";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 60px 0px 12px 0px;
  }
  .siteName {
    color: #144fa9;
    font-family: Cambria;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    background-color: #fed500;
    border-radius: 2px;
    padding: 0px 4px;
  }
  .siteSubtitle {
    color: #fafafa;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .searchFieldBackground {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin-top: 60px;
    padding: 40px 40px;
    position: absolute;
  }
  .popularRoutesText {
    color: #989898;
    font-family: "MacPaw Fixel Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-top: 20px;
  }
  .popularRoutesBox {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 6px 8px 4px 8px;
    width: max-content;
    height: max-content;
    display: grid;
    place-items: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
    cursor: pointer;
    border: 2px solid transparent;
    margin-right: 12px;
  }
  .popularRoutesBox:hover {
    border: 2px solid var(--BlueL, #144fa9);
  }
  .moreBox {
    border-radius: 10px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
    cursor: pointer;
    border: 1px solid silver;
  }
}
@media only screen and (max-width: 1023px) {
  .background {
    background-color: #172b69;
    height: 742px;
    margin: 0;
  }
  .siteTitle {
    color: #fafafa;
    font-family: "MacPaw Fixel";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px 0px 12px 0px;
  }
  .siteName {
    color: #144fa9;
    font-family: Cambria;
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    background-color: #fed500;
    border-radius: 2px;
    padding: 0px 4px;
  }
  .siteSubtitle {
    color: #fafafa;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .searchFieldBackground {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    padding: 12px;
  }
  .popularRoutesText {
    color: #989898;
    font-family: "MacPaw Fixel Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 12px 0px 0px 0px !important;
  }
  .popularRoutesBox {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 6px 8px 4px 8px;
    width: max-content;
    height: max-content;
    display: grid;
    place-items: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
    cursor: pointer;
    border: 2px solid transparent;
    margin-right: 12px;
    margin-top: 20px;
  }
  .popularRoutesBox:hover {
    border: 2px solid var(--BlueL, #144fa9);
  }
  .popularRoutesBox:nth-child(1) {
    margin-top: 12px;
  }
  .popularRoutesBox:nth-child(2) {
    margin-top: 12px;
  }
  .moreBox {
    border-radius: 10px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
    cursor: pointer;
    border: 1px solid silver;
    margin-top: 20px;
  }
}
</style>