import requestService from "../requestService";
import store from '@/store';

export default {
	async createRoute(form) {
		const response = await requestService.post(`/cp_dash/route`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async createPopularRoute(form) {
		const response = await requestService.post(`/cp_dash/popular`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async updateRoute(id, form) {
		const response = await requestService.post(`/cp_dash/route/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getRoute(id) {
		const response = await requestService.get(`/cp_dash/route/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getRouteBySlug(slug) {
		const response = await requestService.get(`/v1/route/${slug}`, { headers: { 'Accept-Language': store.getters.locale.toLowerCase() } })
		return response?.data
	},
	async getRouteAdmin(id) {
		const response = await requestService.get(`/cp_dash/route/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getRouteForAdmin(page, search, sortBy, sortDesc) {
		const response = await requestService.get(`/cp_dash/route?page=${page}${search && search !== 'All' ? `&search=${search}` : ''}${sortBy ? `&sortBy=${sortBy}&sortDesc=${sortDesc}` : ''}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getRouteStatistic() {
		const response = await requestService.get(`/cp_dash/statistic/routes`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async changeRouteStatus(route_id) {
		const response = await requestService.post(`/cp_dash/route/status/${route_id}`, {}, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getRoutes(page, country_iso) {
		const response = await requestService.get(`/v1/route${country_iso && country_iso !== 'All' ? `/countries/${country_iso}` : ''}?page=${page}`, { headers: { 'Accept-Language': store.getters.locale.toLowerCase() } })
		return response?.data
	},
	async getPopularRoutes() {
		const response = await requestService.get(`/v1/route/popular`, { headers: { 'Accept-Language': store.getters.locale.toLowerCase() } })
		return response?.data
	},
	async getRoutesForMain() {
		const response = await requestService.get(`/v1/route/random/6`, { headers: { 'Accept-Language': store.getters.locale.toLowerCase() } })
		return response?.data
	},
	async getCountriesForMain() {
		const response = await requestService.get(`/v1/route/countries`, { headers: { 'Accept-Language': store.getters.locale.toLowerCase() } })
		return response?.data
	},
	async getSymlinkForAdmin() {
		const response = await requestService.get(`/cp_dash/popular/symlink`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async deleteRoute(uuid) {
		const response = await requestService.delete(`/route/${uuid}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getPrice(id) {
		const response = await requestService.get(`/cp_dash/route/price/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async savePrices(id, form) {
		const response = await requestService.post(`/cp_dash/route/price/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
}