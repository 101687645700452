<template>
  <main-component
    @showNavigationDrawer="$emit('showNavigationDrawer')"
    @showProfileNavigationDrawer="$emit('showProfileNavigationDrawer')"
  />
</template>

<script>
import mainComponent from "@/components/User/Main/mainComponent.vue";
export default {
  components: { mainComponent },
  mounted() {
    this.$vuetify.goTo(0, 0);
  },
};
</script>

<style lang="scss">
</style>