var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('img',{staticStyle:{"position":"absolute","right":"0"},attrs:{"src":require("@/assets/img/backgroundMap.png"),"width":"630px","alt":"map","loading":"lazy"}}),_c('Header',{on:{"showNavigationDrawer":function($event){return _vm.$emit('showNavigationDrawer')},"showProfileNavigationDrawer":function($event){return _vm.$emit('showProfileNavigationDrawer')}}}),_c('v-main',[_c('v-container',[_c('h1',{staticClass:"siteTitle"},[_vm._v(" "+_vm._s(_vm._f("localize")("site_title"))+" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('br'):_vm._e(),_c('span',{staticClass:"siteName"},[_vm._v("P-TRANS")])]),_c('p',{staticClass:"siteSubtitle"},[_vm._v(" "+_vm._s(_vm._f("localize")("site_subtitle"))+" ")]),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticClass:"searchFieldBackground"},[_c('search-ticket-form'),_c('p',{staticClass:"popularRoutesText"},[_vm._v(" "+_vm._s(_vm._f("localize")("popular_destination_label"))+" ")]),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_vm._l((_vm.popularSymlinksList),function(route){return _c('div',{key:route.id,staticClass:"popularRoutesBox",on:{"click":function($event){_vm.$router.push(
                  `/search/${route.departure.id}/${
                    route.destination.id
                  }?departureDate=${new Date()
                    .toISOString()
                    .substring(0, 10)}&qty=1`
                )}}},[_vm._v(" "+_vm._s(route.departure.translations?.find( (translate) => translate.lang.toLowerCase() == _vm.locale.toLowerCase() )?.name)+" - "+_vm._s(route.destination.translations?.find( (translate) => translate.lang.toLowerCase() == _vm.locale.toLowerCase() )?.name)+" ")])}),_c('div',{staticClass:"moreBox",on:{"click":function($event){return _vm.$router.push('/routes')}}},[_c('p',{staticStyle:{"margin":"0px"}},[_vm._v(_vm._s(_vm._f("localize")("more_label")))]),_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-chevron-right")])],1)],2)],1)]),_c('RoutesComponent',{style:(_vm.$vuetify.breakpoint.smAndDown
            ? 'margin-top: 80px'
            : 'margin-top: 460px')})],1)],1),_c('MainSwiper',{style:(_vm.$vuetify.breakpoint.smAndDown ? 'margin-top: 80px' : 'margin-top: 140px')}),(_vm.popularRouteList.length > 0)?_c('PopularRouteList',{attrs:{"routeList":_vm.popularRouteList}}):_vm._e(),_c('ChooseUs'),_c('div',{attrs:{"loading":"lazy"}},[_c('iframe',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? '375px' : '700px',"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '100%',"src":_vm.link,"frameborder":"0","allowfullscreen":"","loading":"lazy","title":"Youtube video"}})]),_c('AboutUsComponent'),_c('OurFleetComponent'),_c('ReviewComponent'),_c('FAQComponent'),_c('BlogComponent'),_c('Footer'),_c('footer-for-all-pages')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }